<template>
  <validation-observer ref="tambahLeader">
    <b-card title="Tambah Akun Leader">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Nama Lengkap"
            label-for="namaLengkap"
          >
            <validation-provider
              #default="{ errors }"
              name="Nama Lengkap"
              rules="required"
            >
              <b-form-input
                id="namaLengkap"
                v-model="nama"
                :state="errors.length > 0 ? false:null"
                placeholder="Nama Lengkap"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Email"
            label-for="email"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
            >
              <b-form-input
                id="email"
                v-model="emailValue"
                type="email"
                :state="errors.length > 0 ? false:null"
                placeholder="Email Leader"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Nomor HP"
            label-for="nomorHP"
          >
            <validation-provider
              #default="{ errors }"
              name="Nomor HP"
              rules="required"
            >
              <b-input-group prepend="62">
                <b-form-input
                  id="nomorHP"
                  v-model="no_hp"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Nomor HP"
                  type="number"
                />
              </b-input-group>
              <small class="text-secondary mb-2">Format : 628xxxxxxxxx</small>
              <br>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                  id="register-password"
                  v-model="password"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  :state="errors.length > 0 ? false:null"
                  name="register-password"
                  placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Role"
          >
            <validation-provider
              #default="{ errors }"
              name="Role"
              rules="required"
            >
              <v-select
                v-model="role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="text"
                :options="[{value: 'leader', text: 'Leader'}, {value: 'wakil_leader', text: 'Wakil Leader'},]"
                placeholder="Leader atau Wakil Leader"
                :clearable="false"
                @option:selected="getLeader()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="role.value === 'wakil_leader'"
          md="6"
        >
          <b-form-group
            label="Sambungkan ke Leader"
          >
            <validation-provider
              #default="{ errors }"
              name="Leader"
              rules="required"
            >
              <v-select
                v-model="updateLeader"
                label="label"
                :clearable="false"
                :options="leaderList"
                class="Invoice-filter-select mb-1"
                placeholder="Pilih Leader"
                @option:selected="getAreaLeader()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="role.value === 'wakil_leader' && updateLeader"
          md="6"
        >
          <b-form-group
            label="Pilih Area Leader"
          >
            <validation-provider
              #default="{ errors }"
              name="Area Leader"
              rules="required"
            >
              <v-select
                v-model="areaLeader"
                label="label"
                :clearable="false"
                :options="areaLeaderList"
                class="Invoice-filter-select mb-1"
                placeholder="Pilih Area Leader"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="role.value === 'leader'"
          md="12"
        >
          <b-row class="align-items-end">
            <b-col md="3">
              <b-form-group
                label="Layanan"
              >
                <validation-provider
                  ref="layanan"
                  #default="{ errors }"
                  name="Layanan"
                  rules="required"
                >
                  <v-select
                    v-model="layanan"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="vertikaloptions"
                    placeholder="Layanan"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Provinsi"
              >
                <validation-provider
                  ref="provinsi"
                  #default="{ errors }"
                  name="Provinsi"
                  rules="required"
                >
                  <v-select
                    v-model="provinsi"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="provinsiOptions"
                    placeholder="Provinsi"
                    :clearable="false"
                    @option:selected="getKota()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Kota"
              >
                <validation-provider
                  ref="kota"
                  #default="{ errors }"
                  name="Kota"
                  rules="required"
                >
                  <v-select
                    ref="kotaSelect"
                    v-model="kota"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="kotaOptions"
                    placeholder="Kota"
                    :clearable="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="1">
              <b-button
                variant="primary"
                block
                class="mb-1"
                @click.prevent="addLeaderArea"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="16"
                />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="area.length"
          md="12"
          class="mb-1"
        >
          <b-badge
            v-for="item in area"
            :key="item.value"
            variant="light-primary"
            class="mr-50"
            @click="deleteArea(item)"
          >
            <h6 class="text-primary">
              {{ item.text }}
              <feather-icon
                icon="XIcon"
                size="32"
              />
            </h6>
          </b-badge>
        </b-col>
        <b-col
          md="6"
          offset="6"
        >
          <b-button
            variant="primary"
            class="btn-block"
            @click.prevent="submitLeader"
          >
            <span v-if="!loading">Submit</span>
            <b-spinner
              v-else
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </validation-observer>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BSpinner,
  BBadge,
  BInputGroupAppend,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Ripple from 'vue-ripple-directive'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import regionJson from '@/db/region.json'
import { db, storage, auth } from '@/firebase'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    BInputGroupAppend,
    ToastificationContent,
    vSelect,
    BBadge,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      region: regionJson,
      vertikaloptions: [
        { value: 'vl01', text: 'Akupunktur' },
        { value: 'vl02', text: 'Auto' },
        { value: 'vl03', text: 'Barber' },
        { value: 'vl04', text: 'Bekam' },
        { value: 'vl05', text: 'Beauty' },
        { value: 'vl06', text: 'Cleaning' },
        { value: 'vl07', text: 'Massage' },
        { value: 'vl08', text: 'Cuci AC' },
      ],
      provinsiOptions: [],
      kotaOptions: [],
      layanan: '',
      nama: '',
      emailValue: '',
      no_hp: '',
      provinsi: '',
      kota: '',
      role: '',
      password: '',
      fotoUser: undefined,
      loading: false,
      area: [],
      required,
      items: [{
        id: 1,
        prevHeight: 0,
      }],
      nextLayananId: 2,
      leaderList: [],
      areaLeaderList: [],
      updateLeader: '',
      areaLeader: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    db.collection('region').get().then(querySnapshot => {
      const arr = []
      querySnapshot.forEach(doc => {
        arr.push({
          id: doc.id,
          text: doc.data().nama_provinsi,
        })
      })
      this.provinsiOptions = [...new Set(arr)]
    })
  },
  methods: {
    getKota() {
      this.$refs.kotaSelect.clearSelection()
      db.collection('region').doc(this.provinsi.id).collection('kota').get()
        .then(querySnapshot => {
          const arr = []
          querySnapshot.forEach(doc => {
            arr.push({
              id: doc.id,
              text: doc.data().nama_kota,
            })
          })
          this.kotaOptions = [...new Set(arr)]
        })
    },
    async submitLeader() {
      /* eslint-disable no-unused-vars */
      this.$refs.tambahLeader.validate().then(success => {
        if (success) {
          this.loading = true
          if (this.no_hp.charAt(0) === 0) {
            this.no_hp = this.no_hp.substring(1)
          }
          auth.createUserWithEmailAndPassword(this.emailValue, this.password).then(async userData => {
            let idl = '' // id leader
            let areaLeader = [`${this.layanan.value}-${this.kota.id}`]

            if (this.area.length) {
              areaLeader = []
              this.area.forEach(el => {
                areaLeader.push(el.value)
              })
            }

            if (this.role.value === 'wakil_leader') {
              idl = this.updateLeader.id
              areaLeader = [this.areaLeader.value]
            }

            const submitData = {
              nama: this.nama,
              email: this.emailValue,
              no_hp: `62${this.no_hp}`,
              role: this.role.value,
              access: {
                mitra: {
                  add: true,
                  read: true,
                  area: areaLeader,
                  leader_id: idl,
                },
              },
              uid: userData.user.uid,
              status: 1,
              waktu_daftar: +new Date(),
            }
            await db.collection('mecarehub').doc().set(submitData).then(() => {
              if (this.role.value === 'leader') {
                db.collection('mecarehub').where('email', '==', this.emailValue).get().then(snapshot => {
                  db.collection('mecarehub').doc(snapshot.docs[0].id).set({
                    access: {
                      mitra: {
                        add: true,
                        read: true,
                        area: areaLeader,
                        leader_id: snapshot.docs[0].id,
                      },
                    },
                  }, { merge: true })
                })
              }
              this.loading = false
              this.$router.push({ name: 'leader' })
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil Submit',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
          }).catch(error => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },

    dokumenError(file, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: 'EditIcon',
          variant: 'danger',
        },
      })
    },

    async uploadFotoUser(file) {
      const filetype = file.name.split('.').slice(-1)[0]
      const metadata = {
        contentType: `image/${filetype}`,
      }
      const ref = storage.ref().child(`Images/mecarehub/62${this.no_hp}/fotoUser.${filetype}`)
      await ref.put(file, metadata)

      this.fotoUser = await ref.getDownloadURL()
    },

    getLeader() {
      db.collection('mecarehub').where('role', '==', 'leader').orderBy('nama', 'asc').get()
        .then(snapshot => {
          const arr = []
          snapshot.docs.forEach(doc => {
            const layananLeader = this.vertikalMitra(doc.data().access.mitra.area[0].split('-')[0])
            arr.push({
              id: doc.id,
              label: `${doc.data().nama} - Leader ${layananLeader}`,
              area: doc.data().access.mitra.area[0].split('-')[1],
              raw: doc.data(),
            })
          })
          this.leaderList = [...new Set(arr)]
        })
    },
    getAreaLeader() {
      const kotaLeader = this.updateLeader.raw.access.mitra.area

      kotaLeader.forEach(region => {
        const layananLeader = this.vertikalMitra(region.split('-')[0])
        const areanyaLeader = this.kotaLeader(region.split('-')[1])
        this.areaLeaderList.push({
          label: `${layananLeader} ${areanyaLeader.toUpperCase()}`,
          value: region,
        })
      })
    },
    /* eslint-disable arrow-body-style */
    kotaLeader(area) {
      const fromjson = regionJson.find(item => {
        return item.id === area
      })

      const region = fromjson.name.split(' ')

      region.shift()

      const kota = region.join(' ')

      return kota.toLowerCase()
    },
    vertikalMitra(vl) {
      const vertikalVariant = {
        vl01: 'Akupunktur',
        vl02: 'Auto',
        vl03: 'Barber',
        vl04: 'Bekam',
        vl05: 'Beauty',
        vl06: 'Cleaning',
        vl07: 'Massage',
        vl08: 'Cuci AC',
      }

      return vertikalVariant[vl]
    },
    addLeaderArea() {
      this.area.push({
        value: `${this.layanan.value}-${this.kota.id}`,
        text: `${this.layanan.text} ${this.kota.text}`,
      })
      this.$refs.layanan.reset()
      this.$refs.kota.reset()
      this.$refs.provinsi.reset()
    },
    deleteArea(item) {
      const index = this.area.indexOf(item)
      if (index > -1) {
        this.area.splice(index, 1)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.Mitra-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

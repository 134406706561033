var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"tambahLeader"},[_c('b-card',{attrs:{"title":"Tambah Akun Leader"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nama Lengkap","label-for":"namaLengkap"}},[_c('validation-provider',{attrs:{"name":"Nama Lengkap","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"namaLengkap","state":errors.length > 0 ? false:null,"placeholder":"Nama Lengkap"},model:{value:(_vm.nama),callback:function ($$v) {_vm.nama=$$v},expression:"nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","type":"email","state":errors.length > 0 ? false:null,"placeholder":"Email Leader"},model:{value:(_vm.emailValue),callback:function ($$v) {_vm.emailValue=$$v},expression:"emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nomor HP","label-for":"nomorHP"}},[_c('validation-provider',{attrs:{"name":"Nomor HP","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{attrs:{"prepend":"62"}},[_c('b-form-input',{attrs:{"id":"nomorHP","state":errors.length > 0 ? false:null,"placeholder":"Nomor HP","type":"number"},model:{value:(_vm.no_hp),callback:function ($$v) {_vm.no_hp=$$v},expression:"no_hp"}})],1),_c('small',{staticClass:"text-secondary mb-2"},[_vm._v("Format : 628xxxxxxxxx")]),_c('br'),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":[{value: 'leader', text: 'Leader'}, {value: 'wakil_leader', text: 'Wakil Leader'} ],"placeholder":"Leader atau Wakil Leader","clearable":false},on:{"option:selected":function($event){return _vm.getLeader()}},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.role.value === 'wakil_leader')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Sambungkan ke Leader"}},[_c('validation-provider',{attrs:{"name":"Leader","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"Invoice-filter-select mb-1",attrs:{"label":"label","clearable":false,"options":_vm.leaderList,"placeholder":"Pilih Leader"},on:{"option:selected":function($event){return _vm.getAreaLeader()}},model:{value:(_vm.updateLeader),callback:function ($$v) {_vm.updateLeader=$$v},expression:"updateLeader"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4250648474)})],1)],1):_vm._e(),(_vm.role.value === 'wakil_leader' && _vm.updateLeader)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Pilih Area Leader"}},[_c('validation-provider',{attrs:{"name":"Area Leader","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"Invoice-filter-select mb-1",attrs:{"label":"label","clearable":false,"options":_vm.areaLeaderList,"placeholder":"Pilih Area Leader"},model:{value:(_vm.areaLeader),callback:function ($$v) {_vm.areaLeader=$$v},expression:"areaLeader"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,342697339)})],1)],1):_vm._e(),(_vm.role.value === 'leader')?_c('b-col',{attrs:{"md":"12"}},[_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":"Layanan"}},[_c('validation-provider',{ref:"layanan",attrs:{"name":"Layanan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.vertikaloptions,"placeholder":"Layanan","clearable":false},model:{value:(_vm.layanan),callback:function ($$v) {_vm.layanan=$$v},expression:"layanan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3300641167)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Provinsi"}},[_c('validation-provider',{ref:"provinsi",attrs:{"name":"Provinsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.provinsiOptions,"placeholder":"Provinsi","clearable":false},on:{"option:selected":function($event){return _vm.getKota()}},model:{value:(_vm.provinsi),callback:function ($$v) {_vm.provinsi=$$v},expression:"provinsi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,413069609)})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Kota"}},[_c('validation-provider',{ref:"kota",attrs:{"name":"Kota","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{ref:"kotaSelect",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.kotaOptions,"placeholder":"Kota","clearable":false},model:{value:(_vm.kota),callback:function ($$v) {_vm.kota=$$v},expression:"kota"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,481926554)})],1)],1),_c('b-col',{attrs:{"md":"1"}},[_c('b-button',{staticClass:"mb-1",attrs:{"variant":"primary","block":""},on:{"click":function($event){$event.preventDefault();return _vm.addLeaderArea.apply(null, arguments)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"16"}})],1)],1)],1)],1):_vm._e(),(_vm.area.length)?_c('b-col',{staticClass:"mb-1",attrs:{"md":"12"}},_vm._l((_vm.area),function(item){return _c('b-badge',{key:item.value,staticClass:"mr-50",attrs:{"variant":"light-primary"},on:{"click":function($event){return _vm.deleteArea(item)}}},[_c('h6',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(item.text)+" "),_c('feather-icon',{attrs:{"icon":"XIcon","size":"32"}})],1)])}),1):_vm._e(),_c('b-col',{attrs:{"md":"6","offset":"6"}},[_c('b-button',{staticClass:"btn-block",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitLeader.apply(null, arguments)}}},[(!_vm.loading)?_c('span',[_vm._v("Submit")]):_c('b-spinner',{attrs:{"small":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }